.startupproject-heading {
  font-size: 30px;
  line-height: 1.1;
  font-family: "Google Sans Regular";
  margin: 0px;
}
.startupproject-subtitle {
  font-size: 20px;
  line-height: 1.1;
  font-family: "Google Sans Regular";
  margin: 0px;
  margin-bottom: 10px;
}
.card-image {
  width: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.card-title {
  font-size: 25px;
  line-height: 1.1;
  font-family: "Roboto";
  margin: 0px;
}
.card-subtitle {
  font-size: 20px;
  line-height: 1.1;
  font-family: "Roboto";
  margin: 0px;
  margin-bottom: 10px;
}
.project-card {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  padding: 1.5rem;
  border-radius: 10px;
  border: 1px solid rgba(211, 211, 211, 0.397);
  justify-content: center;
}
.project-card-light {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.project-card-dark {
  box-shadow: 0px 10px 30px -15px #d9dbdf;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.25s ease;
}

.project-detail {
  text-align: center;
}
.project-card-light:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}
.project-card-dark:hover {
  box-shadow: #d9dbdf 0px 20px 30px -10px;
}
.project-image {
  position: relative;
  height: 250px;
  overflow: hidden;
  text-align: center;
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem 1rem;
}

.project-card-footer span.project-tag {
  background: #313131;
  color: #f5f2f4;
  vertical-align: middle;
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  white-space: nowrap;
  line-height: 1.5;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0 0.75em;
  cursor: pointer;
  transition: 0.2s ease-in;
}
span.project-tag:hover {
  background: #acacac;
  color: #000000;
}
/* Media Query */
@media (max-width: 1380px) {
  .startupproject-heading {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .startupproject-heading {
    font-size: 30px;
    text-align: center;
  }
  .startupproject-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
}
